export const flags = {
  FE_DECK_THUMBNAIL: true,
  FE_71_COLOR_MAPPING: true,
  FE_170_PREVENT_TEXT_DRAG_ON_EDIT: true,
  FE_CANVAS_REFACTOR: true,
  FE_278_QUOTE_QUILL: true,
  FE_TEMPLATES: true,
  FE_261_AI_EDIT_TEXT: true,
  FE_PLEASE_WAIT_SCREEN_STUCK: true,
  FE_FIX_DEFAULT_COLOR_SELECTION: true,
  FE_304_QUOTE_IMPROVEMENTS_2: true,
  FE_DUPLICATE_TEXT: true,
  FE_422_IMAGE_OPACITY: true,
  FE_421_IMAGE_CORNER_RADIUS: true,
  FE_277_IMAGE_GRID_IMPROVEMENTS: true,
  FE_419_ADMIN_LOGIN_AS_USER: true,
  FE_259_COPY_PASTE: true,
  FE_604_TEMPLATES_DECORS_FONTS: true,
  FE_591_SWAP_BACKGROUND_COLOR_FIX: true,
  FE_579_TEXT_BOX_COLOR_CHANGE: true,
  FE_450_PAYMENT_CONFIRMATION: true,
  FE_544_SLIDE_CARD_CHART_RE_RENDER_FIX: true,
  FE_631_TRY_REDIRECTION: true,
  FE_402_ORGANIZATIONS_REFACTOR: true,
}

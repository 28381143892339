import React, { useEffect } from 'react'
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom'
import { useFlags, useWindowSize } from 'src/hooks'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, setIsOrgMenuOpen } from 'src/store'
import { theme } from 'src/theme'
import { OrganizationMenu } from './components'
import { orgPageStyles, orgViewWrapperStyles } from './styles'
import { useOrgApi } from 'src/hooks/api/useOrgApi'
import { PaymentConfirmation } from 'src/components/payment-confirmation/payment-confirmation'

export const OrganizationPage: React.FC = React.memo(() => {
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const { isOrgMenuOpen, workspaceId, isPaymentConfirmationModalOpen } =
    useSelector(({ org, workspace, plan }: RootState) => ({
      isOrgMenuOpen: org.isOrgMenuOpen,
      workspaceId: workspace.id,
      isPaymentConfirmationModalOpen: plan.isPaymentConfirmationModalOpen,
    }))
  const { getOrgDetails, getOrgUsers } = useOrgApi()
  const navigate = useNavigate()
  const flags = useFlags()

  const initialMenuOpen = width >= theme.breakpoints.laptop ? true : false

  useEffect(() => {
    dispatch(setIsOrgMenuOpen(initialMenuOpen))

    if (workspaceId) {
      getOrgDetails(workspaceId)
      getOrgUsers(workspaceId)
    } else {
      navigate('/dashboard', { replace: true })
    }
  }, [initialMenuOpen, workspaceId])

  const handleClickMenuIcon = () => {
    dispatch(setIsOrgMenuOpen(!isOrgMenuOpen))
  }

  return (
    <div css={orgPageStyles}>
      <OrganizationMenu
        isMenuOpen={isOrgMenuOpen}
        onClose={handleClickMenuIcon}
      />
      <div css={orgViewWrapperStyles}>
        <Outlet />
      </div>

      {flags.FE_450_PAYMENT_CONFIRMATION && isPaymentConfirmationModalOpen && (
        <PaymentConfirmation />
      )}
    </div>
  )
})

type ContextType = { handleClickMenuIcon: () => void }
export function useHandleClick() {
  return useOutletContext<ContextType>()
}

import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import NoLayoutImage from 'src/assets/images/no-layout.svg'

import {
  RootState,
  SAVE_STATE,
  setAddSlideModal,
  setAllowDeleteShortcut,
  setSaveState,
  slideCreate,
} from 'src/store'
import { useTheme } from 'src/theme'
import { TabSelection } from './components'
import { CREATE_SLIDE_TAB, ICreateSlideModal } from './types'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  CardLayout,
  INPUT_THEME,
  Icon,
  Input,
  Modal,
  Spinner,
  TOOLTIP_THEME,
  Tooltip,
  icons,
} from 'src/lib'
import { createSlideModalStyles, noLayoutViewStyles } from './styles'
import { useLanguage } from 'src/hooks'
import { looseObject } from 'src/types'
import { ComponentTypes, SVGTypes } from 'src/types/api/enums'
import { ComponentFormDataSchema } from 'src/types/api/requestObjects'
import { DateTimeServices } from 'src/services'

export const CreateSlideModal: React.FC<ICreateSlideModal> = React.memo(() => {
  const { t } = useLanguage()
  const { colors, dimensions } = useTheme()

  const dispatch = useDispatch()

  const [selectedTab, setSelectedTab] = useState<CREATE_SLIDE_TAB>(
    CREATE_SLIDE_TAB.PREDEFINED,
  )

  const [searchText, setSearchText] = useState('')

  const { slideModalData, layouts, themeId, userFullname } = useSelector(
    ({ canvas, layouts, edit, user }: RootState) => ({
      slideModalData: canvas.addSlideModal,
      layouts: layouts,
      themeId: edit.activeDeck.data?.deckData?.theme.id,
      userFullname: user.data?.fullName,
    }),
  )

  const [isReady, setIsReady] = useState(false)

  useLayoutEffect(() => {
    if (slideModalData.isOpen) {
      setTimeout(() => {
        setIsReady(true)
      }, 10)
    }
  }, [slideModalData.isOpen])

  const handleOnClose = useCallback(() => {
    setIsReady(false)
    dispatch(setAddSlideModal({ isOpen: false }))
  }, [])

  const onCloseClick = useCallback(() => {
    setIsReady(false)
    dispatch(setAddSlideModal({ isOpen: false }))
  }, [])

  const tabLayouts = useMemo(() => {
    switch (selectedTab) {
      case CREATE_SLIDE_TAB.PREDEFINED:
        return layouts?.predefined
      case CREATE_SLIDE_TAB.OWN:
        return layouts?.own
      case CREATE_SLIDE_TAB.SHARED:
        return layouts?.sharedOnOrg

      default:
        return []
    }
  }, [selectedTab, layouts])

  const filteredLayouts = useMemo(() => {
    if (!searchText.length) {
      return tabLayouts
    }

    const filtered = tabLayouts?.filter(({ name }) =>
      name.toLowerCase().includes(searchText.toLowerCase()),
    )

    return filtered
  }, [tabLayouts, searchText])

  const onSlideAdd = useCallback(
    (value?: number) => {
      const layoutComponents = [
        ...layouts.own,
        ...layouts.predefined,
        ...layouts.sharedOnOrg,
      ]
        .find(({ id }) => id === value)
        ?.slideLayoutComponents.map(({ component }) => {
          if (component.type === ComponentTypes.FORM) {
            return {
              ...component,
              data: {
                ...(component.data as ComponentFormDataSchema),
                formId: uuidv4(),
                questions: (
                  component.data as ComponentFormDataSchema
                ).questions.map((question) => ({
                  ...question,
                  questionId: uuidv4(),
                })),
              },
            }
          }
          return component
        })

      const tempSvg = [
        ...layouts.own,
        ...layouts.predefined,
        ...layouts.sharedOnOrg,
      ].find(({ id }) => id === value)?.tempSvg

      const layoutData = [
        ...layouts.own,
        ...layouts.predefined,
        ...layouts.sharedOnOrg,
      ].find(({ id }) => id === value)

      dispatch(
        slideCreate({
          layoutComponents,
          targetSlide: slideModalData.targetSlide,
          tempSvg: tempSvg as looseObject,
          svgType: value ? layoutData?.svgType : SVGTypes.GENERIC_1,
          background: layoutData?.background,
          extra: {
            userFullname: userFullname,
            fullDate: DateTimeServices.formatDateLongerMonth(new Date()),
          },
        }),
      )

      setIsReady(false)
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      dispatch(setAddSlideModal({ isOpen: false }))
    },
    [slideModalData, layouts],
  )

  const layoutNameTranslation = useCallback(({ name }: { name: string }) => {
    return name
  }, [])

  const renderLayouts = useMemo(() => {
    return filteredLayouts?.map((layoutData) => (
      <CardLayout
        key={uuidv4()}
        id={layoutData.id}
        name={layoutNameTranslation({
          name: layoutData.name,
        })}
        components={layoutData.slideLayoutComponents.map(
          ({ component }) => component,
        )}
        onClick={() => onSlideAdd(layoutData.id)}
        showDeleteButton={selectedTab === CREATE_SLIDE_TAB.OWN}
        svgUrl={themeId && (layoutData.tempSvg as any)?.[themeId]}
        svgType={layoutData.svgType}
        background={layoutData.background}
        thumbnailUrl={layoutData.thumbnailUrl || undefined}
      />
    ))
  }, [filteredLayouts, onSlideAdd])

  const blankLayout = useMemo(
    () =>
      selectedTab === CREATE_SLIDE_TAB.PREDEFINED && (
        <CardLayout
          key={uuidv4()}
          id={0}
          name={t('layout.blank')}
          components={[]}
          solidColor="#F0F7FF"
          onClick={() => onSlideAdd(0)}
        />
      ),
    [selectedTab, onSlideAdd],
  )

  const noLayoutView = useMemo(() => {
    const noLayouText =
      selectedTab === CREATE_SLIDE_TAB.OWN
        ? t('edit.create_slide.no_custom_layout_text')
        : t('edit.create_slide.no_shared_layout_text')
    return (
      selectedTab !== CREATE_SLIDE_TAB.PREDEFINED &&
      !filteredLayouts.length && (
        <div css={noLayoutViewStyles}>
          <img src={NoLayoutImage} />
          <div>{noLayouText}</div>
        </div>
      )
    )
  }, [selectedTab, filteredLayouts])

  return (
    <Modal isOpen={slideModalData.isOpen} onClose={handleOnClose}>
      <div css={createSlideModalStyles}>
        <div className="inner">
          <Icon
            className="close-button"
            icon={icons.close}
            color={colors.white.DEFAULT}
            size={16}
            onClick={onCloseClick}
          />
          <TabSelection
            className="tab-buttons"
            onChange={setSelectedTab}
            selected={selectedTab}
          />
          <div className="search-area">
            <Input
              theme={INPUT_THEME.DARK}
              icon={icons.find}
              placeholder={t('edit.add_new_slide_modal.search_slide_type')}
              value={searchText}
              onChange={setSearchText}
              onFocus={() => dispatch(setAllowDeleteShortcut(false))}
              onBlur={() => dispatch(setAllowDeleteShortcut(true))}
            />
            <Tooltip
              theme={TOOLTIP_THEME.DARK}
              text={t('common.informative.coming_soon')}
              width={282}
            >
              <div>
                <Button
                  size={BUTTON_SIZE.XLARGE}
                  isLink
                  text={t('edit.create_slide.copy_from_another_deck')}
                  type={BUTTON_TYPE.GHOST}
                  theme={BUTTON_THEME.WHITE}
                  icon={icons.copy}
                  disabled
                />
              </div>
            </Tooltip>
          </div>
          {!isReady && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Spinner size={32} />
            </div>
          )}
          <div className="layouts">
            <div className="layouts-inner">
              {isReady && (
                <>
                  {blankLayout}
                  {renderLayouts}
                  {noLayoutView}
                </>
              )}
              <div
                style={{ width: `${dimensions.layoutCardWidth.DEFAULT}px` }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
})

CreateSlideModal.displayName = 'CreateSlideModal'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { titleStyles, areaStyles } from './styles'
import { CreateCard, CREATECARD_TYPE } from 'src/lib/create-card'
import { useLanguage, useFlags } from 'src/hooks'

import scratchImg from 'src/assets/images/start-from-scratch.svg'
import templateImg from 'src/assets/images/start-with-a-template.svg'
import { useNavigate } from 'react-router-dom'
import { Modal, TOOLTIP_THEME, Tooltip } from 'src/lib'
import { CreateWithAiForm } from 'src/components/create-with-ai-form'
import { useDispatch } from 'react-redux'
import { AI_FLOW_STEP, setAiFlowStep, setPrompt } from 'src/store'
import { useAiFlowApi } from 'src/hooks/api/useAiFlowApi'
import { cypress_ids } from 'src/data'
import { APP_CONFIG } from 'src/config'

export const DashboardCreate: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const navigate = useNavigate()
  const { getAiFlowAudience } = useAiFlowApi()
  const flags = useFlags()

  const [openModal, setOpenModal] = useState(false)
  const dispatch = useDispatch()

  const onScratchClick = useCallback(() => {
    navigate('/init/scratch', { replace: true })
  }, [])

  const onTemplateClick = useCallback(() => {
    navigate('/init/template', { replace: true })
  }, [])

  const onCreateWithAi = useCallback(() => {
    dispatch(setAiFlowStep(AI_FLOW_STEP.MODAL))
    setOpenModal(!openModal)
  }, [openModal])

  const [landingPrompt, setLandingProp] = useState<string | undefined>()

  useEffect(() => {
    const landPrompt = localStorage.getItem('landingPrompt')

    if (landPrompt) {
      setLandingProp(landPrompt)
    }
  }, [])

  useEffect(() => {
    const localTryParam = localStorage.getItem('try')
    if (localTryParam) {
      if (localTryParam === 'ai') {
        dispatch(setAiFlowStep(AI_FLOW_STEP.PROMPT))
        setOpenModal(true)
      }
      if (localTryParam === 'import') {
        dispatch(setAiFlowStep(AI_FLOW_STEP.IMPORT))
        setOpenModal(true)
      }

      localStorage.removeItem('try')
    }
  }, [])

  useEffect(() => {
    if (landingPrompt) {
      nextButton()
    }
  }, [landingPrompt])

  const nextButton = useCallback(async () => {
    if (!landingPrompt) {
      return
    }

    const encodedPrompt = atob(landingPrompt)
    const decodedPrompt = decodeURIComponent(encodedPrompt)
    const prompt = JSON.parse(decodedPrompt).prompt
    setOpenModal(true)
    dispatch(setAiFlowStep(AI_FLOW_STEP.AUDIENCE))
    dispatch(setPrompt({ prompt }))
    await getAiFlowAudience({
      prompt,
    })
  }, [landingPrompt])

  const onCloseHandler = useCallback(() => {
    setOpenModal(!openModal)
  }, [openModal])

  const createTemplateRender = useMemo(() => {
    if (flags.FE_TEMPLATES) {
      return (
        <CreateCard
          img={templateImg}
          text={t('dashboard.create.create_with_a_template')}
          onClick={onTemplateClick}
          dataAttr={{ 'data-cy': cypress_ids.DASHBOARD_TEMPLATE_CARD }}
        />
      )
    } else {
      return (
        <Tooltip
          text={t('common.informative.coming_soon')}
          theme={TOOLTIP_THEME.DARK}
        >
          <div>
            <CreateCard
              img={templateImg}
              text={t('dashboard.create.create_with_a_template')}
              onClick={onTemplateClick}
              disabled={!APP_CONFIG.features.startWithATemplate}
              dataAttr={{ 'data-cy': cypress_ids.DASHBOARD_TEMPLATE_CARD }}
            />
          </div>
        </Tooltip>
      )
    }
  }, [flags.FE_TEMPLATES])

  return (
    <div>
      <div css={titleStyles}>{t('dashboard.create.create_presentation')}</div>
      <div css={areaStyles}>
        <CreateCard
          img={scratchImg}
          text={t('dashboard.create.start_from_scratch')}
          onClick={onScratchClick}
          dataAttr={{ 'data-cy': cypress_ids.DASHBOARD_SCRATCH_CARD }}
        />
        {createTemplateRender}
        <CreateCard
          type={CREATECARD_TYPE.GRADIENT}
          text={t('create_with_ai.create_with_ai')}
          onClick={onCreateWithAi}
        />
        {openModal && (
          <Modal
            isOpen={openModal}
            onClose={onCloseHandler}
            preventClickOutside={true}
          >
            <CreateWithAiForm onCloseClick={onCloseHandler} />
          </Modal>
        )}
      </div>
    </div>
  )
})

import { useEffect } from 'react'
import env from 'react-dotenv'
import { APP_CONFIG } from 'src/config'
import Gleap from 'gleap'

export const initializeGleap = () => {
  useEffect(() => {
    if (
      APP_CONFIG.chatWidget.disabled.some((route) =>
        window.location.href.includes(route),
      )
    ) {
      Gleap.showFeedbackButton(false)
    } else {
      Gleap.showFeedbackButton(true)
    }
  }, [window.location.href])

  useEffect(() => {
    Gleap.initialize(env.GLEAP_API_KEY || '')
  }, [])
}

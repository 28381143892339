import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLanguage, useUserApi, useLocation, useDebouncer } from 'src/hooks'
import { RootState, setDecksQuery, setShouldClearSearch } from 'src/store'

import { WorkspaceSelector } from 'src/components/workspace-selector'
import { INPUT_SIZE, INPUT_WIDTH, Input, icons } from 'src/lib'

import { headerStyles, inputContainerStyles, workspaceStyles } from './styles'
import { HEADER_TYPE, IHeader } from './types'

import { DeckNameEdit } from './components/deck-name-edit'
import { HeaderLogo } from './components/header-logo'
import { HeaderActions } from './components/header-actions'
import { EditControls } from './components/edit-controls'

export const Header: React.FC<IHeader> = React.memo(
  ({ type = HEADER_TYPE.DEFAULT, hasSearch = false, className, dataAttr }) => {
    const { path } = useLocation()
    const { t } = useLanguage()
    const { acceptAllInvites } = useUserApi()
    const dispatch = useDispatch()

    const [searchValue, setSearchValue] = useState('')
    const [isInitialRender, setIsInitialRender] = useState(true)

    const { decksQuery, organizations, shouldClearSearch } = useSelector(
      ({ user, decks }: RootState) => ({
        decksQuery: decks.decksQuery,
        organizations: user.organizationUsers,
        shouldClearSearch: decks.shouldClearSearch,
      }),
    )

    useEffect(() => {
      async function acceptInvites() {
        await acceptAllInvites()
      }
      acceptInvites()
    }, [])

    const isWorkspaceVisible = useMemo(
      () => path.includes('dashboard') && organizations?.length,
      [path, organizations],
    )

    const isEditHeader = useMemo(() => type === HEADER_TYPE.DECK_EDIT, [type])

    const leftSideRender = useMemo(() => {
      if (isEditHeader) {
        return <DeckNameEdit />
      }

      return isWorkspaceVisible ? (
        <WorkspaceSelector css={workspaceStyles} />
      ) : (
        <HeaderLogo />
      )
    }, [type, isWorkspaceVisible])

    const debounced = useDebouncer(
      () => {
        dispatch(
          setDecksQuery({
            ...decksQuery,
            search: searchValue.length >= 3 ? searchValue : undefined,
          }),
        )
      },
      { delay: 300 },
    )

    useEffect(() => {
      if (!isInitialRender) {
        debounced()
      } else {
        setIsInitialRender(false)
      }
    }, [searchValue])

    useEffect(() => {
      if (shouldClearSearch) {
        setSearchValue('')
        dispatch(setShouldClearSearch(false))
      }
    }, [shouldClearSearch])

    return (
      <>
        <div className={className} {...dataAttr} css={headerStyles}>
          <div className="left-side">
            {leftSideRender}
            {hasSearch && (
              <div css={inputContainerStyles}>
                <Input
                  placeholder={t('dashboard.search.search_my_decks')}
                  value={searchValue}
                  icon={icons.find}
                  iconSize={16}
                  width={INPUT_WIDTH.FULL}
                  size={INPUT_SIZE.SMALL}
                  onChange={(value) => setSearchValue(value)}
                />
              </div>
            )}
          </div>

          {isEditHeader && <EditControls />}

          <div className="right-side">
            <HeaderActions />
          </div>
        </div>
      </>
    )
  },
)

import React, { useEffect, useMemo, useState } from 'react'
import { useDecksApi } from 'src/hooks'
import { useSelector, useDispatch } from 'react-redux'
import {
  RootState,
  setDecksQuery,
  setActivePage,
  setActiveFolderId,
} from 'src/store'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
  DashboardCreate,
  DashboardFilter,
  DashboardFolders,
  DashboardDecks,
  DashboardPagination,
  DashboardSearchResults,
} from './components'
import { contentStyles, innerStyles } from './styles'
import { CardListLayout } from 'src/layouts/card-list-layout/CardListLayout'
import { sendEcommerceEvent } from 'src/plugins/google/gtag'
import { PlanNames, ViewModes } from 'src/types/api/enums'
import { FOLDER_SHARED_WITH_ME_ID } from './components/DashboardFolders/consts'
import { UpgradeModalManager } from 'src/components/upgrade-modals'
import { encryptSHA256 } from 'src/helpers'
import { ACTION_CLICKS } from 'src/plugins/google/consts'

const DECKS_COUNT_PER_PAGE = 10

export const DashboardPage: React.FC = React.memo(() => {
  const { getDecks, duplicateDeck } = useDecksApi()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    decksQuery,
    isLoading,
    decksCount,
    folders,
    workspaceId,
    workspaceFiltering,
    paymentFailed,
    userEmail,
  } = useSelector(({ decks, folders, workspace, user }: RootState) => ({
    decksQuery: decks.decksQuery,
    isLoading: decks.list.isLoading,
    decks: decks.list.data,
    decksCount: decks.totalCount,
    folders: folders.list,
    workspaceId: workspace.id,
    workspaceFiltering: workspace.filtering,
    paymentFailed: workspace.paymentFailed,
    userEmail: user.data?.email,
    userPlan: user.data?.activeUserPlan,
  }))

  const [isInitialRender, setIsInitialRender] = useState(true)
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<PlanNames | null>(null)

  const numberOfPages = useMemo(() => {
    if (!decksCount) {
      return 1
    } else {
      return Math.ceil(decksCount / DECKS_COUNT_PER_PAGE)
    }
  }, [decksCount])

  const parsedPage = useMemo(
    () => Number(searchParams.get('page')) || 1,
    [searchParams],
  )
  const parsedFolderId = useMemo(
    () => Number(searchParams.get('folderId')),
    [searchParams],
  )
  const parsedSharedWithme = useMemo(
    () => Boolean(searchParams.get('sharedWithMe')),
    [searchParams],
  )

  useEffect(() => {
    const plan = localStorage.getItem('plan')

    if (plan) {
      localStorage.removeItem('plan')
      setIsUpgradeModalOpen(true)
      const encodedPlan = atob(plan)
      const decodedPlan = decodeURIComponent(encodedPlan)

      const planDetails = decodedPlan.split('&')

      planDetails.forEach((param) => {
        const [key, value] = param.split('=')
        if (key === 'plan') {
          if (value === 'pro') {
            setSelectedPlan(PlanNames.PRO)
          } else {
            setSelectedPlan(PlanNames.BUSINESS)
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    const handleTemplateRedirect = async () => {
      const templateParam = searchParams.get('templateDeckId')
      const templateParamLS = localStorage.getItem('templateDeckId')

      if (!templateParam && !templateParamLS) {
        return
      }
      localStorage.removeItem('templateDeckId')
      const resp = await duplicateDeck({
        id: Number(templateParam ?? templateParamLS),
        workspaceId,
      })
      if (resp) {
        navigate(`/deck/${resp}`, { replace: true })
      }
    }

    handleTemplateRedirect()
  }, [])

  useEffect(() => {
    const handleAsync = async () => {
      const paymentParam = searchParams.get('payment') || null

      if (paymentParam === 'success') {
        ;(window as any).rewardful?.('convert', { email: userEmail })
        localStorage.removeItem('payment-awaits')

        const encodedPayloadParam = searchParams.get('payload') || null
        const stringifiedDecodedPayloadParam = decodeURIComponent(
          encodedPayloadParam!,
        )
        const payloadParam = JSON.parse(stringifiedDecodedPayloadParam)

        sendEcommerceEvent({
          ...payloadParam,
          email: userEmail,
          sha256_email_address: await encryptSHA256(userEmail!),
        })

        searchParams.delete('payment')
        searchParams.delete('payload')
        setSearchParams(searchParams)
      }
    }

    handleAsync()
  }, [searchParams])

  useEffect(() => {
    const page = parsedPage > numberOfPages ? numberOfPages : parsedPage
    const folderId = folders.find((folder) => folder.id === parsedFolderId)?.id
    const decksOffset = page * DECKS_COUNT_PER_PAGE - DECKS_COUNT_PER_PAGE
    const query = {
      ...decksQuery,
      folderId,
      offset: decksOffset,
      viewMode:
        workspaceId && workspaceFiltering === 'organization'
          ? ViewModes.ORGANIZATION
          : ViewModes.PRIVATE,
      sharedWithMe: parsedSharedWithme,
    }
    folderId && dispatch(setActiveFolderId(folderId))
    if (parsedSharedWithme) {
      dispatch(setActiveFolderId(FOLDER_SHARED_WITH_ME_ID))
    }
    dispatch(setActivePage(page))

    // do not set decksQuery, if new query is already included as a part of decksQuery
    if (
      Object.entries(query).every(
        (elem) => Object.entries(decksQuery).indexOf(elem) > -1,
      )
    )
      return
    dispatch(setDecksQuery(query))
  }, [parsedPage, parsedFolderId, numberOfPages, parsedSharedWithme])

  useEffect(() => {
    if (!isInitialRender) {
      getDecks()
    } else {
      setIsInitialRender(false)
    }
  }, [workspaceId, decksQuery, isInitialRender])

  const searchParam = useMemo(() => decksQuery.search, [decksQuery.search])

  const dashboardContentRenderer = useMemo(() => {
    if (!workspaceId || (workspaceId && !paymentFailed)) {
      if (searchParam) {
        if (!decksCount) {
          return (
            <>
              <DashboardSearchResults />
            </>
          )
        }
        return (
          <>
            <DashboardSearchResults />
            <DashboardDecks isLoading={isLoading} />
            <DashboardPagination />
          </>
        )
      }
      return (
        <>
          <DashboardCreate />
          <DashboardFilter />
          <DashboardFolders />
          <DashboardDecks isLoading={isLoading} />
          <DashboardPagination />
        </>
      )
    }
  }, [workspaceId, paymentFailed, isLoading, searchParam, decksCount])

  return (
    <div css={contentStyles}>
      <div css={innerStyles}>
        <CardListLayout>{dashboardContentRenderer}</CardListLayout>
        {isUpgradeModalOpen && (
          <UpgradeModalManager
            isOpen={isUpgradeModalOpen}
            callback={() => setIsUpgradeModalOpen(false)}
            context={ACTION_CLICKS.CHOOSE_PLAN_CLICK}
            availablePlanNames={[selectedPlan!]}
            onClose={() => setIsUpgradeModalOpen(false)}
          />
        )}
      </div>
    </div>
  )
})

DashboardPage.displayName = 'DashboardPage'

import React, { useCallback, useEffect, useState } from 'react'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  BUTTON_WIDTH,
  Button,
  INPUT_SIZE,
  INPUT_WIDTH,
  Input,
  Separator,
} from 'src/lib'
import { ILoginForm } from './types'
import { VALIDATION_RULE_TYPES, useAuthApi, useLanguage } from 'src/hooks'
import { useNavigate } from 'react-router-dom'
import { actionsStyles, formInner, welcomeTextStyle } from './styles'
import { cypress_ids } from 'src/data'
import { LottieButton } from '../lottie-button'

export const LoginForm: React.FC<ILoginForm> = React.memo(
  ({ onGoogleAuth, className, dataAttr }) => {
    const navigate = useNavigate()
    const { login, isLoading } = useAuthApi()
    const { t } = useLanguage()
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState(false)

    const onSubmitHandler = useCallback(() => {
      login({ email, password })
    }, [email, password])

    const onSignUpClickHandler = useCallback(() => {
      navigate('/sign-up', { replace: true })
    }, [])

    const onForgotClickHandler = () => {
      navigate('/reset', { replace: true })
    }

    const onEmailChange = (value: string) => {
      setEmail(value)
    }

    const onPasswordChange = (value: string) => {
      setPassword(value)
    }

    const [isFormValid, setIsFormValid] = useState(false)

    useEffect(() => {
      setIsFormValid(emailValid && passwordValid)
    }, [emailValid, passwordValid])

    return (
      <div css={formInner} className={className} {...dataAttr}>
        <div css={welcomeTextStyle}>{t('login.welcome')}</div>
        <Button
          text={t('login.continue_with_google')}
          theme={BUTTON_THEME.GOOGLE}
          width={BUTTON_WIDTH.FULL}
          onClick={onGoogleAuth}
        />
        <Separator text={t('common.or')} />
        <Input
          data-cy-id={cypress_ids.LOGIN_EMAIL_INPUT}
          size={INPUT_SIZE.SMALL}
          width={INPUT_WIDTH.FULL}
          label={t('common.email_address')}
          onChange={onEmailChange}
          value={email}
          onEnter={onSubmitHandler}
          errorAlignRight={true}
          validation={{
            [VALIDATION_RULE_TYPES.REQUIRED]: {
              text: t('validation.error.required', {
                name: t('common.email'),
              }),
            },
            [VALIDATION_RULE_TYPES.VALIDMAIL]: {
              text: t('validation.error.email'),
            },
          }}
          onValidation={setEmailValid}
          name="email"
          dataAttr={{
            'data-cy': cypress_ids.LOGIN_EMAIL_INPUT,
          }}
        />
        <Input
          size={INPUT_SIZE.SMALL}
          width={INPUT_WIDTH.FULL}
          label={t('common.password')}
          type="password"
          onChange={onPasswordChange}
          value={password}
          onEnter={onSubmitHandler}
          errorAlignRight={true}
          validation={{
            [VALIDATION_RULE_TYPES.REQUIRED]: {
              text: t('validation.error.required', {
                name: t('common.password'),
              }),
            },
            [VALIDATION_RULE_TYPES.MIN]: {
              value: 6,
              text: t('validation.min_char', {
                name: t('common.password'),
                value: 6,
              }),
            },
            [VALIDATION_RULE_TYPES.MAX]: {
              value: 72,
              text: t('validation.max_char', {
                name: t('common.password'),
                value: 72,
              }),
            },
          }}
          onValidation={setPasswordValid}
          name="password"
          dataAttr={{ 'data-cy': cypress_ids.LOGIN_PASS_INPUT }}
        />
        <div css={actionsStyles}>
          <Button
            isLink
            size={BUTTON_SIZE.SMALL}
            text={t('login.forgot_password')}
            theme={BUTTON_THEME.PRIMARY}
            type={BUTTON_TYPE.GHOST}
            className="forgot-password"
            onClick={onForgotClickHandler}
          />

          {isFormValid ? (
            <LottieButton
              text={t('login.login')}
              isLoading={isLoading}
              onClick={onSubmitHandler}
              dataAttr={{ 'data-cy': cypress_ids.LOGIN_BUTTON }}
            />
          ) : (
            <Button
              size={BUTTON_SIZE.SMALL}
              text={t('login.login')}
              theme={BUTTON_THEME.GRADIENT}
              isLoading={isLoading}
              onClick={onSubmitHandler}
              width={BUTTON_WIDTH.FULL}
              disabled={!isFormValid}
              dataAttr={{ 'data-cy': cypress_ids.LOGIN_BUTTON }}
            />
          )}

          <div>
            <span>{t('login.dont_have_an_account')}</span>
            <Button
              isLink
              size={BUTTON_SIZE.LARGE}
              text={t('login.signup')}
              theme={BUTTON_THEME.PRIMARY}
              type={BUTTON_TYPE.GHOST}
              onClick={onSignUpClickHandler}
            />
          </div>
        </div>
      </div>
    )
  },
)

import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { useFlags, useLanguage, useLocation } from 'src/hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { Header } from 'src/components/header'
import {
  suspendedWrapper,
  suspendedStyles,
  wrapperStyles,
  headerLayoutStyle,
} from './styles'
import { Icon, icons } from 'src/lib'
import { colors, dimensions } from 'src/theme'
import { Banner } from 'src/components/banner'

export const MainLayout: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const { path } = useLocation()
  const flags = useFlags()

  const {
    paymentFailed,
    isWorkspaceOwner,
    workspaceId,
    adminToken,
    organizations,
  } = useSelector(({ workspace, user, admin }: RootState) => ({
    paymentFailed: workspace.paymentFailed,
    isWorkspaceOwner: workspace.isOwner,
    workspaceId: workspace.id,
    organizations: user.organizationUsers,
    adminToken: admin.adminToken,
  }))

  const isSearchable = useMemo(() => path.includes('dashboard'), [path])

  const isOrganizationActive = useMemo(() => {
    if (!organizations?.length) {
      return true
    }

    return organizations?.find(
      (organization) => organization.organization.id === workspaceId,
    )?.organization.isActive
  }, [organizations, workspaceId])

  const suspendedOrganization = useMemo(
    () => (
      <div css={suspendedWrapper}>
        <div css={suspendedStyles}>
          <Icon icon={icons.suspended} color={colors.error[90]} size={100} />
          <span>
            {isWorkspaceOwner
              ? t('organization.organization_suspended')
              : t('organization.organization_suspended_member')}
          </span>
        </div>
      </div>
    ),
    [workspaceId, isOrganizationActive, isWorkspaceOwner],
  )

  const isAdminBannerVisible = useMemo(() => {
    return flags.FE_419_ADMIN_LOGIN_AS_USER && adminToken
  }, [flags.FE_419_ADMIN_LOGIN_AS_USER, adminToken])

  const calcWrapperMaxHeight = useMemo(() => {
    if (!flags.FE_419_ADMIN_LOGIN_AS_USER) {
      return {
        maxHeight: `calc(100vh - ${dimensions.headerHeight.DEFAULT}px)`,
      }
    }
    return adminToken
      ? {
          maxHeight: `calc(100vh - ${
            dimensions.headerHeight.DEFAULT + dimensions.banner.DEFAULT
          }px);`,
        }
      : { maxHeight: `calc(100vh - ${dimensions.headerHeight.DEFAULT}px)` }
  }, [flags.FE_419_ADMIN_LOGIN_AS_USER, adminToken])

  return (
    <>
      {isAdminBannerVisible && <Banner adminToken={adminToken} />}
      <Header css={headerLayoutStyle} hasSearch={isSearchable} />
      {workspaceId &&
      (flags.FE_402_ORGANIZATIONS_REFACTOR
        ? !isOrganizationActive
        : paymentFailed) ? (
        suspendedOrganization
      ) : (
        <div css={wrapperStyles} style={calcWrapperMaxHeight}>
          <Outlet />
        </div>
      )}
    </>
  )
})

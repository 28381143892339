import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  RootState,
  setAdminToken,
  setDeckQueryToInitial,
  setWorkspaceClearState,
} from 'src/store'
import {
  useClickOutside,
  useLanguage,
  useAdminApi,
  useFlags,
  useUserApi,
} from 'src/hooks'
import {
  adminPageWrapperStyles,
  adminViewWrapperStyles,
  menuStyles,
  actionRowStyles,
  userNameStyles,
} from './styles'
import { AdminMenu, AdminSearch } from './components'
import { formatDateTime } from 'src/services/dateTimeServices'
import {
  UserTable,
  Button,
  Menu,
  icons,
  BUTTON_THEME,
  BUTTON_TYPE,
  Modal,
  Dialog,
} from 'src/lib'
import { theme } from 'src/theme'
import { useAuthProvider } from 'src/provider'
import { RequestServices } from 'src/services'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const AdminPage: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const { loginAsUser, deleteUser } = useAdminApi()
  const { setToken } = useAuthProvider()
  const navigate = useNavigate()
  const flags = useFlags()
  const dispatch = useDispatch()
  const { getOrganizations } = useUserApi()
  const [searchParams, setSearchParams] = useSearchParams()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { adminSearchResult } = useSelector(({ admin }: RootState) => ({
    adminSearchResult: admin.user,
  }))

  const deleteClickHandler = useCallback(
    (flag: boolean) => {
      setIsDeleteModalOpen(flag)
    },
    [isDeleteModalOpen],
  )

  const onDeleteUser = useCallback(async () => {
    if (adminSearchResult?.id) {
      await deleteUser(adminSearchResult?.id.toString())
      setIsDeleteModalOpen(false)
    }
  }, [adminSearchResult])

  const loginAsUserHandler = useCallback(async () => {
    if (adminSearchResult?.id) {
      const adminToken = localStorage.getItem('token')
      localStorage.setItem('adminToken', adminToken!)
      dispatch(setAdminToken({ adminToken: adminToken! }))
      const res = await loginAsUser(adminSearchResult?.id)
      if (res) {
        setToken(res)
        localStorage.setItem('token', res)
        RequestServices.setAuthHeader(res)

        await getOrganizations()
        dispatch(setDeckQueryToInitial())
        dispatch(setWorkspaceClearState())
        searchParams.delete('folderId')
        setSearchParams({})
        navigate('/dashboard', { replace: true })
      }
    }
  }, [adminSearchResult?.id])

  const menuItems = useMemo(() => {
    return [
      {
        label: t('admin.action_menu.login_as_user'),
        icon: icons.user,
        onClick: () => loginAsUserHandler(),
        hidden: !flags.FE_419_ADMIN_LOGIN_AS_USER,
      },
      {
        label: t('common.actions.delete'),
        icon: icons.trash_can,
        onClick: () => deleteClickHandler(true),
        color: theme.colors.error.DEFAULT,
      },
    ]
  }, [
    loginAsUserHandler,
    adminSearchResult?.id,
    flags.FE_419_ADMIN_LOGIN_AS_USER,
  ])

  const menuRef = useRef(null)
  const closeMenu = useCallback(() => setIsMenuOpen(false), [])
  useClickOutside(menuRef, closeMenu)

  const adminContentTemplate = useMemo(() => {
    if (adminSearchResult) {
      const name = (
        <>
          <div css={userNameStyles}>{adminSearchResult.fullName}</div>
          <div>{adminSearchResult.email}</div>
        </>
      )
      const date = <div>{formatDateTime(adminSearchResult.createdAt)}</div>
      const action = (
        <div css={actionRowStyles}>
          <Button
            icon={icons.menu_horizontal}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            theme={BUTTON_THEME.PRIMARY}
            isLink
            type={BUTTON_TYPE.GHOST}
          />
          <div css={menuStyles({ isMenuOpen })} ref={menuRef}>
            <Menu items={menuItems} onItemClick={closeMenu} />
          </div>
        </div>
      )

      return (
        <UserTable
          tableHeaders={[
            t('common.name'),
            t('admin.table.created_date'),
            t('admin.table.actions'),
          ]}
          tableColumns={[name, date, action]}
        />
      )
    }
    return <AdminSearch />
  }, [adminSearchResult, isMenuOpen])

  return (
    <div css={adminPageWrapperStyles}>
      <AdminMenu />
      <div css={adminViewWrapperStyles}>{adminContentTemplate}</div>

      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <Dialog
          title={t('admin.table.delete_user')}
          text={[t('admin.confirm.cannot_undo_delete_user')]}
          onCloseClick={() => setIsDeleteModalOpen(false)}
          onSubmit={onDeleteUser}
          cancelLabel={t('common.actions.cancel')}
          onCancel={() => setIsDeleteModalOpen(false)}
          cancelTheme={BUTTON_THEME.GRAY}
          submitLabel={t('common.actions.delete')}
          submitTheme={BUTTON_THEME.RED}
        />
      </Modal>
    </div>
  )
})
